import { useState, useEffect } from 'react'

import useLayoutEffect from './useIsomorphicLayoutEffect'

export const colorSchemes = {
  light: '(prefers-color-scheme: light)',
  dark: '(prefers-color-scheme: dark)'
}

function onThemeChange(callback) {
  return event => {
    if (!event || !event.matches) {
      return
    }

    callback()
  }
}

export default function useSystemColorScheme() {
  const [scheme, setScheme] = useState<'light' | 'dark' | null>(null)

  useEffect(() => {
    // SSR or matchMedia not supported
    if (typeof window === 'undefined' || !window.matchMedia) {
      return
    }

    const lightMatch = window.matchMedia(colorSchemes.light)
    const onLightMatches = onThemeChange(() => setScheme('light'))

    lightMatch.addListener(onLightMatches)

    const darkMatch = window.matchMedia(colorSchemes.dark)
    const onDarkMatches = onThemeChange(() => setScheme('dark'))

    darkMatch.addListener(onDarkMatches)

    return () => {
      lightMatch.removeListener(onLightMatches)
      darkMatch.removeListener(onDarkMatches)
    }
  }, [])

  useLayoutEffect(() => {
    // SSR or matchMedia not supported
    if (typeof window === 'undefined' || !window.matchMedia) {
      return
    }

    if (window.matchMedia(colorSchemes.dark).matches && scheme !== 'dark') {
      setScheme('dark')
    } else if (window.matchMedia(colorSchemes.light).matches && scheme !== 'light') {
      setScheme('light')
    }
  }, [scheme])

  return scheme
}
