import { customTheme } from '@comparacar/lib/src/theme'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { ReactNode, useEffect, useState } from 'react'

interface PageTransitionInterface {
  children: ReactNode
}

const PageProvider: React.FC<PageTransitionInterface> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  if (!isLoaded) return null

  return (
    <ThemeProvider theme={customTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default PageProvider
