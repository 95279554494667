export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TAG!

export const pageview = (url: URL) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  })
}

export const event = (
  action: Gtag.EventNames | 'A_B_TESTING',
  { event_category, event_label, value }: Gtag.EventParams
) => {
  window.gtag('event', action, {
    event_category,
    event_label,
    value
  })
}
