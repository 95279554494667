import Cookies from 'js-cookie'
import { atom } from 'recoil'

const cookiesEffect = ({ setSelf, onSet }) => {
  const key = 'current_user'
  let currentUser: string | null = Cookies.get(key) || null
  const savedValue = currentUser ? JSON.parse(currentUser) : null
  if (savedValue != null) {
    setSelf({ utm: savedValue.utm, date: new Date(savedValue.date) })
  }

  onSet((newValue, _, isReset) => {
    isReset ? Cookies.remove(key) : Cookies.set(key, JSON.stringify(newValue))
  })
}

const referralState = atom<{ utm: utm | null; date: Date | null }>({
  key: 'referralState',
  default: {
    utm: null,
    date: null
  },
  effects: [cookiesEffect]
})

export default referralState
