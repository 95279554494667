import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink, DefaultOptions } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from 'apollo-link-error'
import * as crossFetch from 'cross-fetch'

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) console.error('GraphQL error', graphQLErrors, response)
  if (networkError) console.error('NetworkError', networkError, response)
})

const additiveLink = from([
  new RetryLink(),
  errorLink as unknown as ApolloLink,
  new HttpLink({ uri: process.env.NEXT_PUBLIC_GRAPHQL_URI, fetch: crossFetch.fetch })
])

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

const client = new ApolloClient({
  ssrMode: true,
  link: additiveLink,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
})

export default client
