export const subscription = () => {
  return [
    {
      questionName: 'Como funciona Carro por Assinatura no comparaCAR?',
      acceptedAnswerText:
        'No comparaCAR é possível visualizar milhares de ofertas de diversas empresas que oferecem carros por assinatura e ainda ser atendido por um especialista que vai encontrar a oferta ideal para você.'
    },
    {
      questionName: 'Motorista de Aplicativo pode assinar um carro no comparaCAR?',
      acceptedAnswerText:
        'Não, as empresas de Carro por Assinatura e as ofertas contidas no comparaCAR não são para motoristas de aplicativo (Uber, 99 e etc.). Várias empresas ofertam locações específicas para esta finalidade.'
    },
    {
      questionName: 'O que é preciso para contratar um carro por assinatura?',
      acceptedAnswerText:
        'a) Ser aprovado através de uma análise de crédito. O processo é bem rápido, em alguns casos imediato, e similar a um financiamento automotivo, mas existem empresas que estão sendo mais flexíveis e aprovando assinaturas para clientes que tiverem seus financiamentos negados. b) Estar com a CNH válida c) Cartão de crédito com limite do valor de pelo menos a primeira mensalidade da assinatura (algumas empresas trabalham apenas com cartão de crédito e outras aceitam pagamento via PIX ou boleto, mas o primeiro pagamento deve ser pago com o cartão de crédito)'
    },
    {
      questionName: 'Qual o prazo para recebimento dos carros por assinatura?',
      acceptedAnswerText:
        'Existem duas opções na maioria das empresas de carro por assinatura: a) Carros a pronta entrega: já estão emplacados e disponíveis para retirada em poucos dias. Neste caso é mais difícil a escolha de cor, final de placa e opcionais. b) Carros sob encomenda (pedido): é possível escolher cor, final de placa e alguns casos até opcionais e customizar o veículo, como instalação de blindagem) mas não há um prazo de entrega garantido. Nesta caso o veículo será encomendado especialmente para o cliente, que pode optar por aguardar a chegada ou utilizar um carro provisório da mesma categoria (nesta caso o pagamento é cobrado mensalmente conforme o contrato).'
    },
    {
      questionName: 'Como funciona o Seguro no carro por assinatura?',
      acceptedAnswerText:
        'Vamos começar pelo que NÃO ESTÁ INCLUSO, como danos da natureza, cobertura de vidro, retrovisor e lanternas. Todos os carros por assinatura tem seguro incluso e sempre há o pagamento de uma franquia em caso de sinistro (assim como no seguro automotivo tradicional) e o valor varia de acordo com cada empresa mas na média é cobrado 5% do valor da Tabela FIPE do veículo. O Seguro de Terceiros não faz parte de todas as empresas de carro por assinatura e algumas ainda cobram uma franquia adicional para utilizar.'
    },
    {
      questionName: 'Como funciona a manutenção no carro por assinatura?',
      acceptedAnswerText:
        'a) Manutenção básica ou preventiva (itens obrigatórios que constam no Manual do Carro) faz parte do pacote oferecido por todas as empresas de carro por assinatura. É de responsabilidade do cliente assinante agendar o serviço e garantir que a manutenção seja realizada. Algumas empresas oferecem o serviço de leva-e-traz. b) Itens de desgaste Natural ou manutenção corretiva - muita atenção pois poucas empresas pagam estes itens.'
    },
    {
      questionName: 'Como funciona o pagamento de Multas no carro por assinatura?',
      acceptedAnswerText:
        'Não se preocupe, é só pagar. Toda a gestão de multas e burocracia fica com a empresa de carro por assinatura.'
    },
    {
      questionName: 'Como funciona o Reajuste no valor das mensalidades?',
      acceptedAnswerText:
        'A maioria das empresas de carro por assinatura tem reajusta no valor mensal do contrato a cada doze meses. O índice de correção mais usado é o IPCA mas algumas poucas empresas utilizam outros índices como IGPM, INCC e etc.'
    },
    {
      questionName: 'Como funciona a Rescisão contratual (cancelamento) no carro por assinatura?',
      acceptedAnswerText:
        'Em todas as empresas é possível cancelar o contrato do carro por assinatura com o pagamento de uma multa. O formato e valor varia muito de uma empresa para outra por isso é necessário verificar sempre antes da assinatura.'
    },
    {
      questionName: 'Como funciona em caso de Roubo e PT (perda total) no carro por assinatura?',
      acceptedAnswerText:
        'Diferente do seguro automotivo tradicional no carro por assinatura também é necessário pagar uma franquia em caso de roubo ou perda total do carro.'
    }
  ]
}

export const buy = () => {
  return [
    {
      questionName: 'Como Funciona?',
      acceptedAnswerText:
        'O ComparaCAR é um MetaSearch (MetaBusca) que consolida todas as ofertas dos portais do segmento automotivo. Antes, o potencial comprador era obrigado a contar com informações de conhecidos, e gastava horas pesquisando na própria internet, que muitas vezes não apontavam as informações e esclarecimentos que ajudariam a definir a melhor opção para cada um.'
    }
  ]
}

export const info = () => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'comparaCAR',
    url: 'https://www.comparacar.com.br/',
    logo: 'https://www.comparacar.com.br/static/favicon.png',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+55 11 953736653',
        contactType: 'customer service'
      }
    ],
    sameAs: [
      'https://www.facebook.com/ComparaCarOficial',
      'https://www.instagram.com/comparacar_oficial/',
      'https://www.youtube.com/channel/UCYsoyy_2PC5bRnovXnS_LBg',
      'https://twitter.com/Compara_car',
      'https://www.linkedin.com/company/comparacar/'
    ]
  }
}
