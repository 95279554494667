import { gql } from '@apollo/client'

import client from '../apollo'

const QUERY_FETCH_MODEL = gql(`
  query autocomplete($term: String!, $hideManufacture: Boolean) {
     autocomplete(term: $term, hideManufacture: $hideManufacture) {
        disabled
        highlight
        label
        type
        value
     }
  }
`)

export async function autocomplete(term: string, hideManufacture?: boolean): Promise<AutocompleteType[]> {
  try {
    const { data } = await client.query<{ autocomplete: AutocompleteType[] }>({
      query: QUERY_FETCH_MODEL,
      variables: { term, hideManufacture }
    })
    return data.autocomplete
  } catch (e) {
    return []
  }
}
