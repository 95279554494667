import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

const CarElectric: React.FC<SvgIconProps> = svgProps => {
  return (
    <SvgIcon {...svgProps} viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M432.118,361.626c-21.469,0-38.872,17.403-38.872,38.872s17.403,38.872,38.872,38.872
			c21.469,0,38.873-17.403,38.873-38.872S453.586,361.626,432.118,361.626z M432.118,416.243c-8.696,0-15.746-7.05-15.746-15.746
			c0-8.696,7.05-15.746,15.746-15.746c8.696,0,15.746,7.05,15.746,15.746C447.864,409.194,440.814,416.243,432.118,416.243z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M215.167,361.626c-21.469,0-38.873,17.403-38.873,38.872s17.403,38.872,38.873,38.872
			c21.469,0,38.872-17.403,38.872-38.872S236.636,361.626,215.167,361.626z M215.167,416.243c-8.696,0-15.746-7.05-15.746-15.746
			c0-8.696,7.05-15.746,15.746-15.746c8.696,0,15.746,7.05,15.746,15.746C230.913,409.194,223.863,416.243,215.167,416.243z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M275.144,113.776c3.431-4.267,5.489-9.684,5.489-15.585c0-13.748-11.146-24.894-24.894-24.894
			c-0.111,0-0.219,0.007-0.33,0.008c-2.494-10.228-11.713-17.821-22.709-17.821c-12.911,0-23.379,10.467-23.379,23.379
			c0,3.725,0.875,7.243,2.425,10.369c-15.055,3.9-26.175,17.577-26.175,33.852c0,19.311,15.655,34.966,34.968,34.966
			c0.181,0,0.36-0.011,0.542-0.013c-0.008,0.036-0.017,0.07-0.025,0.106c-11.566,1.012-20.641,10.716-20.641,22.545
			c0,11.654,8.808,21.247,20.129,22.496c0.109,10.672,8.789,19.291,19.487,19.291c10.765,0,19.491-8.726,19.491-19.491
			c0-8.006-4.827-14.881-11.73-17.881c6.81-1.416,12.493-5.893,15.557-11.927c15.6-2.171,27.612-15.557,27.612-31.756
			C290.962,129.642,284.608,119.352,275.144,113.776z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M174.192,153.955c-4.136-1.049-8.338,1.451-9.39,5.586c-2.755,10.847-9.752,38.394-12.625,49.703
			c-20.136,9.668-15.555,7.468-37.593,18.05c-42.264-25.125-51.109-30.358-51.109-30.358s10.258,2.462,37.73,10.182l0.039-0.458
			c0.956-11.112-7.278-20.893-18.389-21.849l-35.878-3.084c-11.111-0.956-20.894,7.278-21.849,18.389
			c-1.072,12.471-7.95,103.305-7.95,103.305c0.147,1.238-1.006-6.198,9.769,62.432L1.05,434.524
			c-3.167,8.398,1.073,17.775,9.471,20.942c8.394,3.167,17.773-1.071,20.942-9.47l27.415-72.686
			c0.993-2.632,1.285-5.478,0.849-8.256l-9.227-58.766l8.572,0.736c5.27,11.66,21.276,47.081,25.93,57.381l18.066,79.458
			c1.993,8.764,10.714,14.234,19.451,12.244c8.752-1.99,14.235-10.698,12.244-19.45l-18.428-81.055
			c-0.242-1.062-0.589-2.097-1.038-3.09l-22.339-49.433l3.219-37.45c-15.167-14.556-35.858-34.932-45.926-45.089
			c0,0,56.393,33.549,56.726,33.748c0.001,0.001,0.003,0.001,0.004,0.002c3.781,2.248,8.665,2.54,12.777,0.568
			c0.001-0.001,0.002-0.001,0.004-0.002l48.778-23.421c6.734-3.232,9.588-11.319,6.347-18.071c-1.28-2.668-3.442-4.91-6.22-6.278
			c5.129-20.188,3.928-15.462,11.11-43.741C180.828,159.209,178.327,155.006,174.192,153.955z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle cx="73.565" cy="143.181" r="29.893" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M489.495,196.613c-2.206-10.024-11.255-17.299-21.518-17.299H310.812c-7.966,0-15.333,4.318-19.225,11.269l-46.926,83.796
			l-52.798-55.629c0.151,9.477-4.259,18.582-12.219,24.255l51.609,55.32l-0.132,0.236h-26.466c-2.513,0-4.895,1.124-6.493,3.064
			c-1.598,1.94-2.244,4.493-1.762,6.959l1.411,7.221c0.354,1.811-0.235,3.676-1.562,4.956s-3.214,1.801-5.01,1.381l-12.274-2.865
			c-1.616-0.377-3.316,0.004-4.616,1.035c-1.3,1.032-2.058,2.599-2.058,4.259v15.755c0,2.363-1.527,4.456-3.778,5.178l-8.313,2.664
			c-2.476,0.793-4.036,3.222-3.735,5.806c1.002,8.611,3.111,26.735,3.111,26.738c8.173-22.89,30.1-39.226,55.591-39.226
			c32.402,0,59.015,26.388,59.015,59.015h98.92c0-32.617,26.602-59.015,59.015-59.015c32.396,0,59.016,26.382,59.016,59.015h12.011
			c4.891,0,8.856-3.965,8.856-8.856v-93.086H512L489.495,196.613z M364.811,321.27h-26.306c-5.563,0-10.071-4.509-10.071-10.071
			s4.509-10.071,10.071-10.071h26.306c5.563,0,10.071,4.509,10.071,10.071S370.374,321.27,364.811,321.27z M385.625,277.507h-114.58
			l41.008-73.23l18.843,22.297c-1.728,3.198-5.948,11.008-7.611,14.084c0.912,1.748,6.298,12.073,6.27,12.02
			c0.274,0.526,0.888,0.777,1.454,0.593c0.564-0.184,0.913-0.749,0.825-1.335l-1.506-10.046c5.924-5.446,9.681-8.901,15.628-14.37
			c-3.692-9.524,0.214,0.551-9.169-23.66h48.838V277.507z M411.762,277.508v-73.646h54.193l16.203,73.646H411.762z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  )
}

export default CarElectric
