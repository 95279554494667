import { DefaultSeoProps } from 'next-seo'

const props = (url: string): DefaultSeoProps => {
  let protocalDomain
  const match = url.match(/^(https?:\/\/[^/]+)/)
  if (match) {
    protocalDomain = match[1]
  }
  return {
    openGraph: {
      site_name: 'Comparacar',
      type: 'website',
      images: [{ url: `${protocalDomain}/static/preview-image.jpeg` }],
      url
    },
    facebook: {
      appId: '768653041617515'
    },
    twitter: {
      site: '@Compara_car',
      cardType: 'summary_large_image'
    },
    additionalMetaTags: [
      {
        property: 'name',
        content: `ComparaCar`
      }
    ]
  }
}

export default props
