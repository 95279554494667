const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const VERCEL_GIT_COMMIT_SHA = process.env.VERCEL_GIT_COMMIT_SHA || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
export const sentryServerOpts: Record<string, unknown> = {
  dsn: SENTRY_DSN || 'https://30c6685884a64f0cba77601e6683543a@o485814.ingest.sentry.io/6400092',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  release: VERCEL_GIT_COMMIT_SHA || 'develop',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: ['TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.']
}
export const sentryEdgeOpts: Record<string, unknown> = {
  dsn: SENTRY_DSN || 'https://30c6685884a64f0cba77601e6683543a@o485814.ingest.sentry.io/6400092',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  release: VERCEL_GIT_COMMIT_SHA || 'develop',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: ['TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.']
}

export const sentryClientOpts: Record<string, unknown> = {
  dsn: SENTRY_DSN || 'https://30c6685884a64f0cba77601e6683543a@o485814.ingest.sentry.io/6400092',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  release: VERCEL_GIT_COMMIT_SHA || 'develop',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'AxiosError: Network Error'
  ]
}
